import React, { Component } from 'react';
import './about-us.css';

export class AboutUs extends Component {
    static displayName = AboutUs.name;

    render() {
        return (
            <div class='body'>
                <div class='about-us'>
                    <div class='about-us-text center'>
                        <h1>OUR STORY & MISSION</h1>
                        <p>At The Magical Vapors, we're dedicated to providing our valued customers with unparalleled experiences in the world of smoking alternatives. Based in Nashville, TN, and serving the greater Middle Tennessee community, we have five conveniently located stores for all your needs. Our mission is simple: bring you the highest quality products at affordable prices while maintaining exceptional customer service.</p>
                        <p>In our selection, you'll find everything from nicotine-based vape juices that cater to the preferences of smokers looking for alternative options to traditional cigarettes, to the relaxing effects of THC and CBD vaporizers. We pride ourselves on being a one-stop-shop for both medical and recreational users alike. Our inventory includes an extensive variety of THC vapes, derived from premium strains known for their potency and flavor profiles.</p>
                        <p>For those who prefer a more natural approach, we carry THCa flower – the precursor to THC that retains its properties when consumed raw or heated. Additionally, we stock an impressive array of THC edibles such as gummies, chocolates, and baked goods for a discreet, enjoyable experience.</p>
                        <p>Our commitment to holistic wellbeing extends further with our selection of Kratom – a botanical product known for its potential benefits. Available in various forms including powders, capsules, and extracts, Kratom offers a versatile option for those seeking alternatives to common solutions.</p>
                        <p>When you walk into any of our Magical Vapors locations, expect nothing less than expert advice from knowledgeable staff members passionate about their craft. Whether you're new to the scene or already a seasoned connoisseur, our team will guide you through the vast universe of smoking alternatives so you leave satisfied every time. Come visit us today at one of our Nashville-area stores and discover the diverse range of products we have to offer.</p>
                    </div>
                    <div class='about-us-image'>
                        <img alt='' src='/img/about-us-image.png' />
                    </div>
                </div>
            </div>
        );
    }
}
