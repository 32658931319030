import React, { Component } from 'react';
import './products.css';

export class Products extends Component {
    static displayName = Products.name;

    render() {
        return (
            <div className='products-body'>
                <div className='products-heading'>
                    <p><h1>OUR CORE PRODUCTS</h1></p>
                </div>
                <div className='products-list'>
                    <div className='products-list-top'>
                        <div className='products'>
                            <div className='product-section-heading'>
                                <span>NICOTINE</span>
                            </div>
                            <div className={'product-list'}>
                                <ul>
                                    <li>Disposables</li>
                                    <li>E-Liquid</li>
                                    <li>Nicotine Salts</li>
                                </ul>
                            </div>
                        </div>
                        <div className='products'>
                            <div className='product-section-heading'>
                                <span>HARDWARE</span>
                            </div>
                            <div className={'product-list'}>
                                <ul>
                                    <li>Pod Systems</li>
                                    <li>Mods</li>
                                    <li>Tanks (Sub-Ohm)</li>
                                </ul>
                            </div>
                        </div>
                        <div className='products'>
                            <div className='product-section-heading'>
                                <span>LIGHTERS</span>
                            </div>
                            <div className={'product-list'}>
                                <ul>
                                    <li>Lighters</li>
                                    <li>Torches</li>
                                    <li>Butane</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className={'products-section-middle'}>
                        <div className={'products-section'}>
                            <div className={'product-section-heading'}>
                                <span>THC</span>
                            </div>
                            <div className={'products'}>
                                <ul>
                                    <li>Delta-8 THC</li>
                                    <li>Delta-9 THC</li>
                                    <li>Delta-10 THC</li>
                                    <li>THCv</li>
                                    <li>THCa</li>
                                </ul>
                                <ul>
                                    <li>Beverages</li>
                                    <li>Carts</li>
                                    <li>Dabs</li>
                                    <li>Disposables</li>
                                    <li>Edibles</li>
                                    <li>Flower</li>
                                    <li>Prerolls</li>
                                </ul>
                            </div>
                        </div>
                        <div className={'products-section'}>
                            <div className={'product-section-heading'}>
                                <span>ACCESSORIES</span>
                            </div>
                            <div className={'products'}>
                                <ul>
                                    <li>Glass Pipes</li>
                                    <li>Water Pipes</li>
                                    <li>Bubblers</li>
                                    <li>Dab Rigs</li>
                                    <li>Screens</li>
                                    <li>Hand-blown Glass</li>
                                </ul>
                                <ul>
                                    <li>Smell Proof Containers</li>
                                    <li>Grinders</li>
                                    <li>Glass Cleaner</li>
                                    <li>Isopropyl Alcohol</li>
                                    <li>Detox Products</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='products-list-bottom'>
                        <div className='products'>
                            <div className='product-section-heading'>
                                <span>ORDOR ELIMINATORS</span>
                            </div>
                            <div className={'product-list'}>
                                <ul>
                                    <li>Sprays</li>
                                    <li>Incense</li>
                                </ul>
                            </div>
                        </div>
                        <div className='products'>
                            <div className='product-section-heading'>
                                <span>ALTERNATIVE</span>
                            </div>
                            <div className={'product-list'}>
                                <ul>
                                    <li>Kava</li>
                                </ul>
                            </div>
                        </div>
                        <div className='products'>
                            <div className='product-section-heading'>
                                <span>Kratom</span>
                            </div>
                            <div className={'product-list'}>
                                <ul>
                                    <li>Capsules</li>
                                    <li>Drinks</li>
                                    <li>Powders</li>
                                    <li>Gummies</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
