import React, { Component } from 'react';
import './apply.css';

export class Apply extends Component {
    static displayName = Apply.name;

    render() {
        return (
            <div class='body'>
                <div class='apply'>
                    <div class='apply-text center'>
                        <h1>JOB APPLICATIONS</h1>
                        <p>To apply for a position at one of our shops, please download the <a href={"/MagicalApplication.pdf"}>application PDF here</a>, fill
                            it out, and email it to <a href={"mailto:themagicalvapors@gmail.com"}>themagicalvapors@gmail.com</a>.</p>
                    </div>
                </div>
            </div>
        );
    }
}
