import React, { Component } from 'react';
import './contact-us.css';

export class ContactUs extends Component {
    static displayName = ContactUs.name;

    render() {
        return (
            <div class='body'>
                <div class='contact-us'>
                    <div class='contact-us-text center'>
                        <h1>CONTACT US</h1>
                        <p>For Business inquiries (including B2B sales), please email us at <a href={"mailto:themagicalvapors@gmail.com"}>themagicalvapors@gmail.com</a>.</p>
                    </div>
                </div>
            </div>
        );
    }
}
