import { Home } from "./components/home/home";
import { LocationsMini } from "./components/locations/locations-mini";
import { AboutUs } from "./components/about-us/about-us";
import { Products } from "./components/products/products";
import {ContactUs} from "./components/contact-us/contact-us";
import {Apply} from "./components/apply/apply";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/locations',
        element: <LocationsMini />
    },
    {
        path: '/products',
        element: <Products />
    },
    {
        path: '/about-us',
        element: <AboutUs />
    },
    {
       path: '/contact-us',
       element: <ContactUs />
    },
    {
        path: '/apply',
        element: <Apply />
    },
    {
        path: '/employment',
        element: <Apply />
    }
];

export default AppRoutes;
