import React, { Component } from 'react';
import {LocationsMini} from "../locations/locations-mini";
import {Products} from "../products/products";
import './home.css';
import {Link} from "react-router-dom";

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div className='body'>
                <div className='logo'>
                    <img src='/img/New-MV-Logo.png' alt='Magical Vapors Logo'/>
                </div>
                <div className='tagline'>
                    <h1>Middle Tennessee's Leader In Premium Vaping Services</h1>
                </div>
                <div className={'application-container center'}>
                    <h2>We are hiring!</h2>
                    <Link to={'/employment'}>
                        <button className={'application-button'}>Click Here to Apply</button>
                    </Link>
                </div>
                <LocationsMini></LocationsMini>
                <Products></Products>
                <div className='shop-hours'>
                    <div className='shop-hours-info'>
                        <h4>SHOP HOURS</h4>
                        <p>Come visit one of our 5 locations in Middle Tennessee</p>
                        <span>
                        Mon - Sat: 9am - 9pm <br />
                        Sun: 11am - 8pm
                        </span>
                    </div>
                    <div className='shop-hours-picture'>
                        <img alt='footer' src='/img/footer-image.webp' />
                    </div>
                </div>
            </div>
        );
    }
}
